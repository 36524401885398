import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Modal } from '@vtblife/uikit';
import { osName } from 'react-device-detect';

import styles from './web-push-accept-modal.module.css';

export const webPushAcceptModalName = 'webPushAcceptModal';

const sendAcceptModalAnalyticEvent = (eventName: string) => {
    return window.dataLayer.push({
        event: 'lk_action',
        page_type: 'lk',
        section: 'all',
        type: eventName,
    });
};

export const WebPushAcceptModal: React.FC<{
    modalStore?: {
        openModal: (name: string) => void;
        closeModal: (name: string) => void;
        isOpen: (name: string) => boolean;
    };
    subscribe: (onSuccess: () => void) => void;
}> = observer(({ modalStore, subscribe }) => {
    const [shouldShowIosInstruction, setShouldShowIosInstruction] = useState<boolean>(false);
    const [isSubscribeBtnDisabled, setIsSubscribeBtnDisabled] = useState<boolean>(false);
    const closeModal = useCallback(() => {
        modalStore?.closeModal(webPushAcceptModalName);
    }, [modalStore]);

    const handleClick = useCallback(() => {
        sendAcceptModalAnalyticEvent('pu_consent_chat_web_push');
        const isStandalone =
            window.matchMedia('(display-mode: standalone)').matches ||
            window.matchMedia('(display-mode: fullscreen)').matches;
        if (osName === 'iOS' && !isStandalone) {
            return setShouldShowIosInstruction(true);
        }
        setIsSubscribeBtnDisabled(true);
        return subscribe(closeModal);
    }, [closeModal, subscribe]);

    React.useEffect(() => {
        sendAcceptModalAnalyticEvent('pu_show_chat_web_push');
    }, []);

    const shouldRenderModal = React.useMemo(() => {
        const isNotStandaloneIos = typeof Notification === 'undefined' && osName === 'iOS';
        if (isNotStandaloneIos) {
            return true;
        }
        const isNotificationsUnsupportedOrDenied =
            typeof Notification === 'undefined' || Notification?.permission === 'denied';
        const isNotificationSubscribed = typeof Notification !== 'undefined' && Notification?.permission === 'granted';
        if (isNotificationsUnsupportedOrDenied || isNotificationSubscribed) {
            return false;
        }
        return true;
    }, []);

    if (!shouldRenderModal) {
        return null;
    }

    return (
        <Modal
            opened={modalStore?.isOpen(webPushAcceptModalName) || false}
            onClose={() => {
                closeModal();
                localStorage.setItem(
                    'webPushAcceptModal',
                    JSON.stringify({
                        lastSeen: new Date().toISOString(),
                        closeType: 'CLOSE',
                    }),
                );
                sendAcceptModalAnalyticEvent('pu_close_chat_web_push');
            }}
            size="s"
        >
            {shouldShowIosInstruction ? (
                <>
                    <Modal.Content>
                        <ol>
                            <li>
                                Проверьте версию вашего iOS, она должна быть выше 16.4: Настройки → Основные →
                                Обновление ПО.
                            </li>
                            <li>Откройте сайт m2.ru в браузере Safari</li>
                            <li>
                                Добавьте сайт на главный экран смартфона: в нижнем меню браузера центральная иконка
                                «Поделиться» → на экран «Домой»
                            </li>
                            <li>На главном экране нажмите на созданную иконку М2 и войдите в личный кабинет</li>
                            <li>Приложение попросит разрешения на отправку уведомлений, нажмите «Разрешить»</li>
                        </ol>
                        <b>Готово — вы будете своевременно получать сообщения о ходе сделки.</b>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className={styles.buttonsContainer}>
                            <Button onClick={closeModal} fullWidth variant="secondary">
                                Закрыть
                            </Button>
                        </div>
                    </Modal.Actions>
                </>
            ) : (
                <>
                    <Modal.Content>
                        <div className={styles.imageContainer}>
                            <img
                                src="https://cdn.m2.ru/assets/file-upload-server/0b8be3f6e7276be5782437a202ecbd8d.png"
                                alt="https://cdn.m2.ru/assets/file-upload-server/0b8be3f6e7276be5782437a202ecbd8d.png"
                            />
                        </div>
                        <Modal.Title>
                            Хотите узнавать о новых сообщениях в чатах? Разрешите отправлять уведомления в браузере
                        </Modal.Title>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className={styles.buttonsContainer}>
                            <Button onClick={handleClick} disabled={isSubscribeBtnDisabled} fullWidth variant="primary">
                                Да, хочу узнавать
                            </Button>
                            <Button
                                onClick={() => {
                                    sendAcceptModalAnalyticEvent('pu_decline_chat_web_push');
                                    localStorage.setItem(
                                        'webPushAcceptModal',
                                        JSON.stringify({
                                            lastSeen: new Date().toISOString(),
                                            closeType: 'CANCELED',
                                        }),
                                    );
                                    closeModal();
                                }}
                                fullWidth
                                variant="secondary"
                            >
                                Нет
                            </Button>
                        </div>
                    </Modal.Actions>
                </>
            )}
        </Modal>
    );
});
