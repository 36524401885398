import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { RoleType } from '@vtblife/event-bus-events';
import { register } from '@vtblife/notification-webpush-api/axios-gen/token';
import { FirebaseApp, initializeApp } from 'firebase/app';

import { daysToMilliseconds, getFirebaseConfig } from '../utils';
import { isAutotestUserAgent } from '../../utils';
import { useAuthorizeContext, useConfigContext } from '../contexts';
import { usePermission } from '../../hooks/use-permission';

export const useNotifications = ({ openModal }: { openModal?: (modalName: string) => void }) => {
    const { features, appEnvironment } = useConfigContext();
    const { user } = useAuthorizeContext();
    const firebaseAppRef = useRef<FirebaseApp>();
    const pathname = useHistory().location.pathname;
    const notificationsState = usePermission({ name: 'notifications' });

    //Операторам хелпдеска модалка должна показываться на любой странице, остальным же только на /personal-area
    const shouldShowByRole = useMemo(
        () =>
            (user?.currentRoleType === RoleType.Realtor ||
                user?.currentRoleType === RoleType.Professional ||
                user?.currentRoleType === RoleType.Client) &&
            pathname === '/personal-area',
        [user, pathname],
    );
    const shouldShowByHelpdeskOperator = useMemo(
        () =>
            user?.permissions.includes('/helpdesk/operator/online-status:update') &&
            features?.some((i) => i.name === 'show-helpdesk-web-push'),
        [user, features],
    );

    const registerToken = useCallback(async () => {
        const serviceWorkerRegistration = await navigator.serviceWorker?.ready;
        const isSupportedFirebaseApi = await isSupported();
        if (isSupportedFirebaseApi) {
            const messaging = getMessaging();
            const token = await getToken(messaging, {
                serviceWorkerRegistration: serviceWorkerRegistration,
            });
            if (
                token !== localStorage.getItem('push_subscription') ||
                user?.userId !== localStorage.getItem('push_subscription_user_id')
            ) {
                await register({
                    data: {
                        token: {
                            value: token,
                            tokenProvider: 'TOKEN_PROVIDER_FIREBASE',
                            browser: {
                                description: navigator?.userAgent,
                            },
                        },
                    },
                });
                localStorage.setItem('push_subscription', token);
                localStorage.setItem('push_subscription_user_id', user?.userId || '');
            }
        }
    }, [user]);

    useEffect(() => {
        firebaseAppRef.current = initializeApp(getFirebaseConfig(appEnvironment || 'test'));
        // Инициализируем firebaseApp строго один раз
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof navigator !== 'undefined') {
            if (typeof Notification !== 'undefined' && Notification.permission === 'granted') {
                const messaging = getMessaging();
                onMessage(messaging, (payload) => {
                    const notificationTitle = payload.notification?.title || payload.data?.title || '';
                    navigator.serviceWorker?.ready.then((res) => {
                        if (!document?.hidden) {
                            res.showNotification(notificationTitle, {
                                ...payload,
                                body: payload?.data?.body || '',
                                icon: payload?.data?.icon,
                            });
                        }
                    });
                });
            }
        }
    }, []);

    useEffect(() => {
        if (user?.isAuthenticated && notificationsState === 'granted') {
            registerToken();
        }
    }, [notificationsState, registerToken, user]);

    useEffect(() => {
        if (user?.isAuthenticated && notificationsState !== 'granted') {
            setTimeout(async () => {
                const webPushAcceptModalInfo = JSON.parse(localStorage.getItem('webPushAcceptModal') || '{}');
                if (
                    webPushAcceptModalInfo.lastSeen &&
                    new Date(webPushAcceptModalInfo.lastSeen).getTime() +
                        daysToMilliseconds(webPushAcceptModalInfo.closeType === 'CANCELED' ? 90 : 1) >
                        Date.now()
                ) {
                    return;
                }
                localStorage.setItem(
                    'webPushAcceptModal',
                    JSON.stringify({
                        lastSeen: new Date().toISOString(),
                    }),
                );
                openModal && openModal('webPushAcceptModal');
            }, 5000);
        }
    }, [openModal, notificationsState, user]);

    const subscribe = useCallback(
        async (closeModal: () => void) => {
            const permission = await Notification?.requestPermission();
            if (permission === 'granted') {
                const serviceWorkerRegistration = await navigator.serviceWorker?.ready;
                const isSupportedFirebaseApi = await isSupported();
                if (isSupportedFirebaseApi) {
                    const messaging = await getMessaging(firebaseAppRef.current);
                    closeModal();
                    const token = await getToken(messaging, {
                        serviceWorkerRegistration: serviceWorkerRegistration,
                    });
                    register({
                        data: {
                            token: {
                                value: token,
                                tokenProvider: 'TOKEN_PROVIDER_FIREBASE',
                                browser: {
                                    description: navigator?.userAgent,
                                },
                            },
                        },
                    });
                    localStorage.setItem('push_subscription', token);
                    localStorage.setItem('push_subscription_user_id', user?.userId || '');
                } else {
                    closeModal();
                }
            }
        },
        [user],
    );

    return {
        subscribe,
        shouldShowSubscribeModal:
            (shouldShowByRole || shouldShowByHelpdeskOperator) && !isAutotestUserAgent(navigator.userAgent),
    };
};
