import React, { useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { TestingComponent } from '@vtblife/uikit/legacy';

import './menu-item-base.module.css';

import { HeaderContextValue } from '../header-context';

export interface MenuItemBaseProps extends TestingComponent {
    url?: string;
    path?: string;
    isActive?: boolean;
    className?: string;
    isCloseMenuDisabled?: boolean;
    onClick?: () => void;
    target?: string;
}

// css-loader doesn't export global, we use globals for critical extraction
const styles = {
    menuItemBase: 'menuItemBase',
};

export const MenuItemBase: React.FC<MenuItemBaseProps> = ({
    url,
    path,
    className,
    isCloseMenuDisabled,
    isActive,
    onClick,
    children,
    target,
    ...restProps
}) => {
    const href = path || url;
    const dataTestProp = restProps.dataTest || restProps['data-test'];
    const { closeMenu, onMenuItemClick, isMenuOpened } = React.useContext(HeaderContextValue);

    const ref = useRef<HTMLElement>(null);

    const handleItemClick = (e: React.MouseEvent) => {
        !isCloseMenuDisabled && closeMenu();
        onClick?.();
        onMenuItemClick?.(e, { path, url, target });
    };

    useLayoutEffect(() => {
        if (isMenuOpened && isActive) {
            ref.current?.scrollIntoView({ block: 'center' });
        }
        // реагируем только в тот момент, когда бургер в открытом состоянии
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMenuOpened]);

    const menuItemProps = {
        href,
        target,
        rel: target === '_blank' ? 'noopener noreferrer' : undefined,
        className: cn(styles.menuItemBase, className),
        onClick: handleItemClick,
        'data-test': dataTestProp,
        ref,
    };

    // leave render in this form for correct ast transformation of <a> component
    // @ts-expect-error
    return href ? <a {...menuItemProps}>{children}</a> : <button {...menuItemProps}>{children}</button>;
};
