import { SidebarItem } from '@vtblife/layout-config/types';
import React, { useContext } from 'react';
import cn from 'classnames';
import Icon from '@vtblife/uikit-icons';

import { MenuItemBase } from '../../menu-item-base/menu-item-base';
import styles from './user-info-menu-item.module.css';
import { HeaderContextValue } from '../../header-context';
import { comparePathname } from '../../utils';

interface UserInfoMenuItemProps {
    item: SidebarItem;
    onClick?: () => void;
    dataTest?: string;
}

const LOGOUT_BUTTON_TO = '/logout';

export const UserInfoMenuItem: React.FC<UserInfoMenuItemProps> = ({ item, onClick, dataTest }) => {
    const { logoutPath, pathname } = useContext(HeaderContextValue);
    const { path, url, icon, displayName, showInDesktopProfileMenu } = item;
    // false if not logout button
    const logoutUrl = (path === LOGOUT_BUTTON_TO || url === LOGOUT_BUTTON_TO) && logoutPath;
    const isActive = comparePathname(pathname, item);
    return (
        <MenuItemBase
            dataTest={dataTest}
            className={cn(styles.userInfoMenuItem, {
                [styles.userInfoMenuItemDesktop]: showInDesktopProfileMenu,
                [styles.userInfoMenuItemActive]: isActive,
            })}
            path={logoutUrl || path}
            url={logoutUrl || url}
            isActive={isActive}
            onClick={onClick}
        >
            {icon && (
                <span className={styles.userInfoMenuItemIcon}>
                    <Icon name={icon} size="m" />
                </span>
            )}
            {displayName}
        </MenuItemBase>
    );
};
