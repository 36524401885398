export interface BasePollOptions {
    interval: number;
    maxAttempts: number;
    attempt?: number;
}

export interface ExceedPollOptions<T> extends BasePollOptions {
    result: T;
}

export interface PollOptions<T> extends BasePollOptions {
    pollFn: () => T | null;
    exceedPollFn?: (data: ExceedPollOptions<T>) => void;
}

export function poll<T>({ pollFn, interval, maxAttempts, exceedPollFn }: PollOptions<T>) {
    let timeoutId: ReturnType<typeof setTimeout>;

    const promise = new Promise((resolve) => {
        const fn = ({ pollFn, interval, maxAttempts, attempt = 0, exceedPollFn }: PollOptions<T>) => {
            timeoutId = setTimeout(() => {
                const result = pollFn();

                if (result) {
                    if (attempt + 1 < maxAttempts) {
                        fn({ pollFn, interval, maxAttempts, attempt: attempt + 1, exceedPollFn });
                    } else {
                        exceedPollFn?.({ interval, maxAttempts, attempt, result });
                        resolve(true);
                    }
                }
            }, interval);
        };

        fn({ pollFn, interval, maxAttempts, exceedPollFn });
    });

    return {
        promise,
        clear: () => {
            clearTimeout(timeoutId);
        },
    };
}
