import { useEffect, useState } from 'react';
import { UpdatingServices } from '@vtblife/layout-config/types';

import { useAuthorizeContext } from '../contexts';

const UPDATES_INTERVAL = 300_000;
export const useDealOnlineUpdates = (refresh: (services: UpdatingServices[]) => void) => {
    const { user } = useAuthorizeContext();
    const [updatedAt, setUpdatedAt] = useState(Date.now());
    const isMortgageDealsAvailable = user?.permissions.includes('/deal-online/mortgage/deals:create');

    useEffect(() => {
        if (!isMortgageDealsAvailable) return;
        refresh?.(['deal-online']);
    }, [updatedAt, refresh, isMortgageDealsAvailable]);

    useEffect(() => {
        if (!isMortgageDealsAvailable) return;

        const intervalId = setInterval(() => {
            if (Date.now() - updatedAt > UPDATES_INTERVAL) {
                setUpdatedAt(Date.now());
            }
        }, 100);
        return () => {
            clearInterval(intervalId);
        };
    }, [refresh, updatedAt, isMortgageDealsAvailable]);

    return null;
};
