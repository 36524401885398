import { useEffect, useState } from 'react';
import { EventBus } from '@vtblife/event-bus';
import { RoleType, User } from '@vtblife/event-bus-events';
import { getAuthenticatedUserMaster } from '@vtblife/person-api/axios-gen/person_service';
import { getChatToken, putChatToken } from '@vtblife/profile-api/axios-gen/user_profile_service';
import Cookies from 'js-cookie';

import { reportErrorToSentry } from '../../utils';
import { ExceedPollOptions, poll } from './poll';
import { useAuthorizeContext } from '../../contexts';

const MAIN_SITE_WIDGET_URL = 'https://support.m2.ru/upload/chats/widget_1_13.js';
const LAST_USEDESK_USER_ID_LS_KEY = 'usedesk-widget__last-user-id';
const USEDESK_MESSENGER_TOKEN_COOKIE_NAME = 'usedesk_messenger_token';
const UNREGISTERED_USER_VALUE = 'UNREGISTERED';

export interface UseDeskChat {
    user: User | null;
    shouldShowWidget?: boolean;
}

const sendUserInfoToChat = async (user: User | null, token: string) => {
    const widget = window.usedeskMessenger;
    if (user?.isAuthenticated) {
        let email = user.username?.includes('@') ? user.username : '';
        let phone = email ? '' : user.username;

        try {
            const details = await getAuthenticatedUserMaster({});
            const { primaryEmail, primaryPhone, secondaryEmails, secondaryPhones } = details?.person?.contacts || {};
            email = primaryEmail?.email || secondaryEmails?.[secondaryEmails?.length - 1]?.email || email;
            phone = primaryPhone?.number || secondaryPhones?.[secondaryPhones?.length - 1]?.number || phone;
        } catch (error) {
            reportErrorToSentry({ error });
        } finally {
            widget?.identify({
                name: user.username,
                token,
                email,
                phone,
            });
        }
    }
};

const handleChatErrorPoll = ({
    interval,
    maxAttempts,
    attempt,
    result,
}: ExceedPollOptions<{ error: string; chatElementHTML?: string; useDeskMessengerHTML?: string }>) => {
    const { error, chatElementHTML, useDeskMessengerHTML } = result;
    reportErrorToSentry({
        error,
        level: 'info',
        extra: {
            interval,
            attempt,
            maxAttempts,
            chatElementHTML,
            useDeskMessengerHTML,
        },
    });
};

const clearChatToken = () => {
    Cookies.remove(USEDESK_MESSENGER_TOKEN_COOKIE_NAME);
};

const setLastUser = (userId: string) => {
    localStorage.setItem(LAST_USEDESK_USER_ID_LS_KEY, userId);
};

const clearChatTokenAndSetLastUser = (userId: string) => {
    clearChatToken();
    setLastUser(userId);
};

export const UseDeskChat = () => {
    const { user } = useAuthorizeContext();
    const [chatElement, setChatElement] = useState<Element | null>(null);
    const [isScriptAppended, setIsScriptAppended] = useState(false);
    const [profileChatToken, setProfileChatToken] = useState<string>();

    useEffect(() => {
        const lastUsedeskUserId = localStorage.getItem(LAST_USEDESK_USER_ID_LS_KEY);
        // Если пользователь авторизован и последний пользователь usedesk
        // был другим или незарегистрированным пользователем,
        // то очищаем значение куки usedesk_messenger_token.
        if (user?.isAuthenticated && lastUsedeskUserId !== user?.userId) {
            clearChatTokenAndSetLastUser(user?.userId);
        }

        if (user?.isAuthenticated && chatElement) {
            const localChatToken = window.usedeskMessenger!.getChatToken();
            getChatToken({})
                .then(({ token }) => {
                    sendUserInfoToChat(user, token || localChatToken);

                    if (token || localChatToken) {
                        setProfileChatToken(token || localChatToken);
                    }

                    if (!token && localChatToken) {
                        putChatToken({ data: { token: localChatToken } });
                    }
                })
                .catch((error) => {
                    sendUserInfoToChat(user, localChatToken);
                    reportErrorToSentry({
                        error,
                        level: 'warning',
                        description: 'Failed to fetch profile usedesk token',
                    });
                });
        }
    }, [user, chatElement]);

    useEffect(() => {
        const handleChatClick = (e: MouseEvent) => {
            if ((e.target as HTMLButtonElement)?.id === 'uw-main-button-close') {
                const lastUsedeskUserId = localStorage.getItem(LAST_USEDESK_USER_ID_LS_KEY);
                if (!user?.isAuthenticated) {
                    // Сразу задаем, что последний пользователь незарегистрированный
                    setLastUser(UNREGISTERED_USER_VALUE);
                    if (lastUsedeskUserId && lastUsedeskUserId !== UNREGISTERED_USER_VALUE) {
                        // Если пользователь не авторизован, а раньше кто-то пользовался юздеском
                        // то чистим куку, чтобы дальше установилась новая
                        clearChatToken();
                    }
                }

                if (user?.isAuthenticated) {
                    // Если предыдущий пользователь был анонимом, то мы должны почистить куку,
                    // чтобы не сохранилась переписка незарегистрированного пользователя
                    if (lastUsedeskUserId === UNREGISTERED_USER_VALUE) {
                        clearChatTokenAndSetLastUser(user?.userId);
                    }
                }
            }
        };

        document.addEventListener('click', handleChatClick);

        return () => {
            document.removeEventListener('click', handleChatClick);
        };
    }, [user]);

    useEffect(() => {
        // Предполагаем, что клик по кнопке виджета будет после того,
        // как придет запрос getChatToken из profile-api
        let isPolling = false;
        let clearPoll: (() => void) | undefined;

        const handleChatClick = (e: MouseEvent) => {
            if (
                (e.target as HTMLButtonElement)?.id === 'uw-button-chat' &&
                user?.isAuthenticated &&
                !profileChatToken &&
                !isPolling
            ) {
                // Даже если клик произошел до ответа из profile-api, то проверим еще раз
                getChatToken({})
                    .then(({ token }) => {
                        if (!token) {
                            isPolling = true;
                            const result = poll<boolean>({
                                maxAttempts: 100,
                                interval: 100,
                                pollFn: () => {
                                    const chatToken = window.usedeskMessenger?.getChatToken();
                                    if (chatToken) {
                                        // Виджет нельзя проинициализировать после открытия,
                                        // поэтому просто сохраним токен
                                        putChatToken({ data: { token: chatToken } });
                                        isPolling = false;
                                        return false;
                                    } else {
                                        return true;
                                    }
                                },
                                exceedPollFn: () => {
                                    isPolling = false;
                                },
                            });

                            clearPoll = result.clear;
                            return result.promise;
                        }

                        // otherwise "Not all code paths return a value.ts(7030)"
                        return;
                    })
                    .catch((error) => {
                        reportErrorToSentry({
                            error,
                            level: 'warning',
                            description: 'Failed to fetch profile usedesk token',
                        });
                    });
            }
        };

        document.addEventListener('click', handleChatClick);

        return () => {
            clearPoll?.();
            window.removeEventListener('click', handleChatClick);
        };
    }, [user, profileChatToken]);

    useEffect(() => {
        const isEmployee = user?.currentRoleType === RoleType.Employee;
        if (isScriptAppended && isEmployee) {
            //Скрываю методом указанным в документации https://api.usedocs.ru/article/49595
            window.usedeskMessenger?.toggle(false);
        }
        if (!window.__shouldUseChatStub && !isEmployee && !isScriptAppended) {
            const widgetUrl = MAIN_SITE_WIDGET_URL;

            const script = document.createElement('script');
            script.async = true;
            script.src = widgetUrl;

            window.__widgetInitCallback = () => {
                poll({
                    interval: 100,
                    maxAttempts: 100,
                    pollFn: () => {
                        const foundChatElement = document.querySelector('.uw__messenger-layout__buttons');
                        const useDeskMessenger = document.getElementById('usedesk-messenger');

                        try {
                            if (foundChatElement?.nodeName === 'DIV') {
                                setChatElement(foundChatElement);
                                if (!foundChatElement.classList.contains('chatWidget')) {
                                    foundChatElement.classList.add('chatWidget', 'chatWidgetSpecificSelector');
                                }
                            } else {
                                return { error: 'foundChatElement is falsy' };
                            }

                            if (useDeskMessenger?.nodeName === 'DIV') {
                                if (useDeskMessenger.childNodes?.[0]?.nodeName === 'DIV') {
                                    (useDeskMessenger.childNodes[0] as HTMLDivElement).style.zIndex = '103';
                                } else {
                                    return { error: 'useDeskMessenger.childNodes[0] is falsy' };
                                }
                            } else {
                                return { error: 'useDeskMessenger is falsy' };
                            }

                            return null;
                        } catch (error) {
                            const chatElementHTML = foundChatElement?.outerHTML;
                            const useDeskMessengerHTML = useDeskMessenger?.outerHTML;
                            return {
                                error,
                                chatElementHTML,
                                useDeskMessengerHTML,
                            };
                        }
                    },
                    exceedPollFn: handleChatErrorPoll,
                });

                EventBus.getInstance().publish({
                    type: 'usedesk:init',
                    data: {},
                    category: 'simple',
                });
            };

            document.head.appendChild(script);
            setIsScriptAppended(true);
        }
    }, [user?.currentRoleType, isScriptAppended, setIsScriptAppended]);

    return null;
};
