import React, { useContext } from 'react';

import { Badge } from '@vtblife/uikit';

import styles from './user-info-menu-content-header.module.css';
import { isAnonymousUser } from '../../../utils';
import { UserInfoMenuContentProfile } from '../user-info-menu-content-profile/user-info-menu-content-profile';
import { useAuthorizeContext } from '../../../shell';
import { useDisplayWallet } from '../../../hooks/use-display-wallet';
import { MenuLink } from '../../menu-link/menu-link';
import { useDisplayVerificationStatus } from '../../../hooks/use-display-verification-status';
import VerificationPassedIcon from '../icons/verification-status.component.svg';
import { HeaderContextValue } from '../../header-context';

export const UserInfoMenuContentHeader: React.FC = () => {
    const { user } = useAuthorizeContext();
    const { shouldDisplayWallet, walletLink, walletText, walletBalance } = useDisplayWallet();
    const { isClientVerificationAvailableToSend, isAlreadyPassedVerification, isVerificationAvailable } =
        useDisplayVerificationStatus();
    const { setIsMenuOpened } = useContext(HeaderContextValue);

    const onPassVerificationClick = () => {
        setIsMenuOpened(false);
        window.location.href = '/partner/company-settings/verification';
    };

    return (
        <div className={styles.userInfoMenuContentHeader}>
            <div className={styles.userInfoMenuContentHeaderProfile}>
                <div className={styles.userInfoMenuContentHeaderVerification}>
                    <span className={styles.userInfoMenuContentHeaderProfileText}>Личный кабинет</span>
                    {isAlreadyPassedVerification && (
                        <span className={styles.userInfoMenuVerificationIcon}>
                            <VerificationPassedIcon />
                        </span>
                    )}
                    {isVerificationAvailable && isClientVerificationAvailableToSend && (
                        <span className={styles.userInfoMenuVerificationIcon} onClick={onPassVerificationClick}>
                            <Badge color="accent">Не верифицирован</Badge>
                        </span>
                    )}
                </div>
                {user && !isAnonymousUser(user.username) && <UserInfoMenuContentProfile user={user} />}
            </div>
            {shouldDisplayWallet && (
                <MenuLink
                    key="wallet"
                    item={{
                        icon: 'm2-wallet',
                        id: 'wallet',
                        displayName: walletBalance ? `Баланс: ${walletText}` : 'Баланс',
                        path: walletLink,
                    }}
                    isOpenByDefault={false}
                />
            )}
        </div>
    );
};
