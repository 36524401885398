export const getProfileHeaderByRoleType = (roleType?: string) => {
    if (!roleType) return null;

    switch (roleType) {
        case 'MAKLER':
        case 'PROFESSIONAL':
            return 'Частный риелтор';
        case 'CLIENT':
            return 'Частное лицо';
        case 'EMPLOYEE':
            return 'Сотрудник М2';
        default:
            return 'Партнёр';
    }
};
