import React from 'react';
import get from 'lodash/get';
import type { History } from 'history';

import { createHistory } from '../compat/history';
import { Shell } from '../components/shell/shell';

interface Props {
    element: JSX.Element;
    props: any;
}

let history: History<any>;

function lazyGetHistory() {
    if (!history) {
        history = createHistory();
    }

    return history;
}

export function wrapPageElement(props: Props) {
    return <WrapProvider {...props} />;
}

const WrapProvider: React.FC<Props> = React.memo(
    ({ props, element }) => {
        const { logoVariant, isFullWidth, isTransparentHeader, hideHeader, hideFooter, modalStore } =
        props.pageContext?.data?.props || {};

        return (
            <Shell
                logoVariant={logoVariant || undefined}
                isFullWidth={isFullWidth}
                isTransparent={isTransparentHeader}
                pathname={props.location?.pathname}
                modalStore={modalStore}
                hasHeader={!hideHeader}
                hasFooter={!hideFooter}
                history={lazyGetHistory()}
            >
                {element}
            </Shell>
        );
    },
    (prevProps, nextProps) => {
        const path = 'props.pathContext.app.originalPath';
        const prev = get(prevProps, path);
        const next = get(nextProps, path);
        return prev && next && prev === next;
    },
);
